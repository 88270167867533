import React from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { Alert, Typography } from "@cuda-networks/bds-core";
import ActionMessageType from "../../../../models/ActionMessageType";
import IChangeServiceSelection from "../../../../models/Products/IChangeServiceSelection";
import ChangeServiceSummaryItem from "../SummaryItem";

interface ISummaryStepProps {
  selection: IChangeServiceSelection;
}

const SummaryStep: React.FC<ISummaryStepProps> = ({ selection }) => {
  return (
    <Grid container style={{ width: "600px" }}>
      <Grid item>
        <Typography data-testid="changeServicesDialogSubtitle" variant="subtitle2" style={{ fontWeight: "600", paddingLeft: "16px", paddingRight: "16px" }}>
          SUMMARY OF CHANGES
        </Typography>
      </Grid>
      <Grid item>
        <Typography data-testid="changeServicesDialogLabel1" variant="subtitle2" style={{ paddingLeft: "16px", paddingRight: "16px" }}>
          Please verify the changes you have selected from previous steps before proceeding.
        </Typography>
      </Grid>
      <div style={{ height: "16px", width: "1px" }} />
      <Grid container item xs={12}>
        <Grid item xs={12} container direction="column">
          <Grid item style={{ paddingBottom: "15px", paddingLeft: "16px", paddingRight: "16px" }}>
            <Alert severity={ActionMessageType.Warning} data-testid="changeServicesWarning">
              <Typography gutterBottom>Caution! This Change of Service is permanent and cannot be undone. </Typography>
              <Typography gutterBottom>The active product/service listed below will be changed to the listed single service.</Typography>
            </Alert>
          </Grid>
          <Grid item></Grid>
          <Grid item spacing={3} style={{ paddingBottom: "1px" }}>
            <Typography data-testid="summaryChangeServicesFromLabel" variant="subtitle2" style={{ fontWeight: "600", paddingLeft: "16px", paddingRight: "16px" }}>
              FROM
            </Typography>
            {selection.from?.map((item, idx) => {
              return (
                <div key={"paccordionDiv" + idx} style={{ marginBottom: "10px", paddingLeft: "16px", paddingRight: "16px" }}>
                  <ChangeServiceSummaryItem name={item.name} serialNumber={item.serial ?? ""} uniqueId={item.subname} />
                </div>
              );
            })}
          </Grid>
          <Grid item spacing={3}>
            <div data-testid={""}>
              <Typography data-testid="summaryChangeServicesToLabel" variant="subtitle2" style={{ fontWeight: "600", paddingLeft: "16px", paddingRight: "16px" }}>
                TO
              </Typography>
              <div key={"paccordionDiv"} style={{ marginBottom: "10px", paddingLeft: "16px", paddingRight: "16px" }}>
                <ChangeServiceSummaryItem name={selection.to?.name ?? ""} serialNumber={selection.from[0]?.serial ?? ""} uniqueId={selection.to?.subname ?? ""} />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryStep;
