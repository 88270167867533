import React, { useEffect, useMemo, useState } from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import DropDown from "../../DropDown";
import IProduct from "../../../models/Products/IProduct";
import { groupBy } from "../../../utility";
import { Typography } from "@cuda-networks/bds-core";

interface IToSelectionItemProps {
  products: IProduct[];
  selectedItem: IProduct | undefined;
  selectedService: string | undefined;
  onToSelectionChanged: (product: IProduct, service: string) => void;
}

const ToSelectionItem: React.FC<IToSelectionItemProps> = ({ products, onToSelectionChanged, selectedItem, selectedService }) => {
  const [selectedBundleId, setSelectedBundleId] = useState(0);
  const [selectedOrderId, setSelectedOrderId] = useState(0);
  const [productOptions, setProductOptions] = useState<string[]>([]);

  const getProductsByBundleName = (bundleName: string, p: IProduct[]) => {
    return p.filter(product => product.name === bundleName);
  };

  const getUniqueBundleNames = (p: IProduct[]) => {
    const productsByBundleName = groupBy(p, "name");
    const bundleNames = Object.getOwnPropertyNames(productsByBundleName);
    bundleNames.sort();
    return bundleNames;
  };

  const bundleOptions = useMemo(() => getUniqueBundleNames(products), [products]);

  useEffect(() => {
    if (selectedItem !== undefined) {
      const index = bundleOptions.findIndex(bundleName => bundleName === selectedItem.name);
      if (index > -1) {
        setSelectedBundleId(index + 1);
        const prodOpt = getProductsByBundleName(bundleOptions[index], products).map(p => p.subname);
        const index1 = prodOpt.findIndex(productName => productName === selectedItem.subname);
        if (index1 > -1) {
          setSelectedOrderId(index1 + 1);
        }
      }
    } else if (selectedService !== undefined) {
      const index = bundleOptions.findIndex(bundleName => bundleName === selectedService);
      if (index > -1) {
        setSelectedBundleId(index + 1);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentOption = bundleOptions[selectedBundleId - 1];
    const options = getProductsByBundleName(currentOption, products).map(p => p.subname);
    options.sort((a, b) => a.localeCompare(b));
    setProductOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBundleId]);

  useEffect(() => {
    const selectedProduct = products.find(p => p.name === bundleOptions[selectedBundleId - 1] && p.subname === productOptions[selectedOrderId - 1]);
    if (selectedProduct) {
      const currentOption = bundleOptions[selectedBundleId - 1];
      onToSelectionChanged(selectedProduct, currentOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrderId]);

  const onBundleSelected = (value: number) => {
    setSelectedBundleId(value);
    setSelectedOrderId(0);
    const currentOption = bundleOptions[value - 1];
    onToSelectionChanged({ id: "", name: "", subname: "" } as IProduct, currentOption); //reset selected product
  };

  const onOrderSelected = (value: number) => {
    setSelectedOrderId(value);
  };

  return (
    <Grid container item xs={12} style={{ backgroundColor: "#d9d9d9", borderRadius: "8px", padding: "20px" }} className="ChangeFrom">
      <Grid item xs={12}>
        <div data-testid="changeActiveServiceToServiceDropdownItem" className="changeActiveServiceFamily">
          <Typography
            data-testid="changeActiveServiceToServiceLabelItem"
            noWrap
            style={{
              fontSize: "12px",
              fontWeight: 700,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            Service
          </Typography>
          <DropDown label={""} options={bundleOptions} selectedOptionId={selectedBundleId} handleChange={onBundleSelected} required={false} error={""} disabled={false} />
        </div>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 20 }}>
        <div data-testid="changeActiveServiceToDescriptionDropdownItem" className="ChangeActiveService">
          <Typography
            data-testid="changeActiveServiceToDescriptionLabelItem"
            noWrap
            style={{
              fontSize: "12px",
              fontWeight: 700,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            Unique ID
          </Typography>
          <DropDown label={""} options={productOptions} selectedOptionId={selectedOrderId} handleChange={onOrderSelected} required={false} error={""} disabled={selectedBundleId === 0} />
        </div>
      </Grid>
    </Grid>
  );
};

export default ToSelectionItem;
