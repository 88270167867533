import { CancelTokenSource } from "axios";
import { Reducer } from "redux";
import { ProductActions, ProductActionTypes } from "../actions/productActions";
import IAccountProducts from "../models/Products/IAccountProducts";
import IProduct from "../models/Products/IProduct";
import IProductFamily from "../models/Products/IProductFamily";
import ISerial from "../models/Products/ISerial";
import IBundleSku from "../models/Products/IBundleSku";
import ProductFamily from "../models/Products/ProductFamily";
import { State } from "@progress/kendo-data-query";
import { ISubpartnersWithProducts } from "../models/Products/SubpartnersWithProducts";
import IAccountOrders from "../models/Products/IAccountOrders";
import IOrder from "../models/Products/IOrder";
import { IDeleteAccountSerial } from "../components/Accounts/DeleteAccount/DeleteAccountSerialsTable";

export interface IProductState {
  productsToDisplay: IProductFamily[];
  expandedStatus: Record<ProductFamily, boolean>;
  loadingProducts: boolean;
  serialsToDisplay: ISerial[];
  selectedProduct?: IProduct;
  subpartnersWithProducts: ISubpartnersWithProducts[];
  loadSubpartnersWithAssignedProductsCancellationTokenSource?: CancelTokenSource;
  parentProductsFamilies: IAccountProducts;
  loadingSubpartnersWithProducts: boolean;
  accountsProducts: IAccountProducts[];
  loadingParentMspProducts: boolean;
  loadParentProductsCancellationTokenSource?: CancelTokenSource;
  hasSubpartnersWithProducts: boolean;
  loadingHasSubpartnersWithProducts: boolean;
  skuServicesRepo: IBundleSku[];
  bbsTableState: State;
  essTableState: State;
  csTableState: State;
  seTableState: State;
  loadingProductsCanceledForAccountId: number;
  loadingProductsForAccountId: number;
  loadingParentProductsCanceled: boolean;
  loadingProductsForSubpartners: boolean;
  loadingCurrency: boolean;
  currency: string;
  currencyCancellationTokenSource?: CancelTokenSource;
  loadingOrdersForAccountId: number;
  accountsOrders: IAccountOrders[];
  serialTableState: State;
  deleteAccountSerialsToDisplay?: IDeleteAccountSerial[];
  allDeleteAccountSerials?: IDeleteAccountSerial[];
  loadingDeleteAccountSerials?: boolean;
  getAllSerialsCancellationTokenSource?: CancelTokenSource;
  allProductIdsRepo: IOrder[];
  deleteAccountSerialTableState: State;
  cancelGetServicesDestinationOptionsTokenSource?: CancelTokenSource;
  loadingOptionsToChangeServicesTo?: boolean;
  cancelGetAccountHasAnyChangeServicesOptionsTokenSource?: CancelTokenSource;
  loadingAccountHasAnyChangeServicesOptions?: boolean;
  accountHasChangeServicesOptions?: boolean;
}

const initialProductState: IProductState = {
  productsToDisplay: [],
  expandedStatus: {
    [ProductFamily.ESSENTIALS_SERIVICES]: true,
    [ProductFamily.BACKUP_APPLIANCES]: true,
    [ProductFamily.CONTENT_SHIELD]: true,
    [ProductFamily.INTRONIS_BACKUP]: true,
    [ProductFamily.SECUREEDGE_SERVICES]: true,
  },
  loadingProducts: false,
  serialsToDisplay: [],
  selectedProduct: undefined,
  subpartnersWithProducts: [],
  parentProductsFamilies: { accountId: 0, productFamilies: [] },
  loadingSubpartnersWithProducts: false,
  accountsProducts: [],
  loadingParentMspProducts: false,
  hasSubpartnersWithProducts: false,
  loadingHasSubpartnersWithProducts: false,
  skuServicesRepo: [],
  bbsTableState: { sort: [{ field: "nameAndModel", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
  essTableState: { sort: [{ field: "subname", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
  csTableState: { sort: [{ field: "subname", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
  seTableState: { sort: [{ field: "subname", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
  loadingProductsCanceledForAccountId: 0,
  loadingProductsForAccountId: 0,
  loadingParentProductsCanceled: false,
  loadingProductsForSubpartners: false,
  loadingCurrency: false,
  currency: "",
  accountsOrders: [],
  loadingOrdersForAccountId: 0,
  serialTableState: { sort: [{ field: "accountName", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
  allProductIdsRepo: [],
  deleteAccountSerialTableState: { sort: [{ field: "productName", dir: "asc" }], take: 15, skip: 0 },
};

export const productReducer: Reducer<IProductState, ProductActions> = (state = initialProductState, action: ProductActions) => {
  switch (action.type) {
    case ProductActionTypes.GET_PRODUCTS: {
      return {
        ...state,
        productsToDisplay: action.productsToDisplay,
        loadingProducts: action.loadingProducts,
      };
    }
    case ProductActionTypes.SET_LOADING_PRODUCTS: {
      return {
        ...state,
        loadingProducts: action.loadingProducts,
      };
    }
    case ProductActionTypes.GET_SERIALS_TO_DISPLAY: {
      return {
        ...state,
        serialsToDisplay: action.serialsToDisplay,
      };
    }
    case ProductActionTypes.ASSIGN_PRODUCTS:
    case ProductActionTypes.UNASSIGN_PRODUCTS:
    case ProductActionTypes.ACTIVATE_PRODUCTS:
    case ProductActionTypes.DEACTIVATE_PRODUCTS:
    case ProductActionTypes.RETRY_ACTIVATION_PRODUCTS:
    case ProductActionTypes.SET_ACTIVATE_ASSIGN_ERROR: {
      return {
        ...state,
      };
    }
    case ProductActionTypes.SET_SELECTED_PRODUCT: {
      return {
        ...state,
        selectedProduct: action.selectedProduct,
      };
    }
    case ProductActionTypes.GET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS: {
      return {
        ...state,
        subpartnersWithProducts: action.subpartnersWithProducts,
        loadingSubpartnersWithProducts: action.loadingSubpartnersWithProducts,
      };
    }
    case ProductActionTypes.SET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS_CANCEL_TOKEN: {
      return {
        ...state,
        loadSubpartnersWithAssignedProductsCancellationTokenSource: action.loadSubpartnersWithAssignedProductsCancellationTokenSource,
      };
    }
    case ProductActionTypes.SET_EXPANDED_STATUS: {
      return {
        ...state,
        expandedStatus: action.expandedStatus,
      };
    }
    case ProductActionTypes.SET_ACCOUNT_PRODUCTS: {
      return {
        ...state,
        accountsProducts: action.accountsProducts,
      };
    }
    case ProductActionTypes.GET_PARENT_MSP_PRODUCTS: {
      return {
        ...state,
        parentProductsFamilies: action.parentProductsFamilies,
        loadingParentMspProducts: action.loadingParentMspProducts,
      };
    }
    case ProductActionTypes.SET_PARENT_PRODUCTS_CANCEL_TOKEN: {
      return {
        ...state,
        loadParentProductsCancellationTokenSource: action.loadParentProductsCancellationTokenSource,
      };
    }
    case ProductActionTypes.GET_HAS_SUBPARTNERS_WITH_PRODUCTS: {
      return {
        ...state,
        hasSubpartnersWithProducts: action.hasSubpartnersWithProducts,
        loadingHasSubpartnersWithProducts: action.loadingHasSubpartnersWithProducts,
        subpartnersWithProducts: action.subpartnersWithProducts,
      };
    }
    case ProductActionTypes.UPDATE_SKU_SERVICES_REPO: {
      return {
        ...state,
        skuServicesRepo: action.skuServicesRepo,
      };
    }
    case ProductActionTypes.SET_TABLE_PROPS_FOR_BBS: {
      return {
        ...state,
        bbsTableState: action.bbsTableState,
      };
    }
    case ProductActionTypes.SET_TABLE_PROPS_FOR_ESS: {
      return {
        ...state,
        essTableState: action.essTableState,
      };
    }
    case ProductActionTypes.SET_TABLE_PROPS_FOR_SE: {
      return {
        ...state,
        seTableState: action.seTableState,
      };
    }
    case ProductActionTypes.SET_TABLE_PROPS_FOR_CS: {
      return {
        ...state,
        csTableState: action.csTableState,
      };
    }
    case ProductActionTypes.SET_LOADING_PRODUCTS_CANCELED: {
      return {
        ...state,
        loadingProductsCanceledForAccountId: action.loadingProductsCanceledForAccountId,
      };
    }
    case ProductActionTypes.SET_LOADING_PRODUCTS_FOR_ACCOUNT_ID: {
      return {
        ...state,
        loadingProductsForAccountId: action.loadingProductsForAccountId,
      };
    }
    case ProductActionTypes.SET_LOADING_PARENT_PRODUCTS_CANCELED: {
      return {
        ...state,
        loadingParentProductsCanceled: action.loadingParentProductsCanceled,
      };
    }
    case ProductActionTypes.GET_PRODUCTS_FOR_SUBPARTNERS: {
      return {
        ...state,
        loadingProductsForSubpartners: action.loadingProductsForSubpartners,
      };
    }
    case ProductActionTypes.CLEAR_PRODUCTS_FROM_SUBPARTNERS_WITH_PRODUCTS: {
      return {
        ...state,
        subpartnersWithProducts: action.subpartnersWithProducts,
      };
    }
    case ProductActionTypes.GET_CURRENCY: {
      return {
        ...state,
        currency: action.currency,
        loadingCurrency: action.loadingCurrency,
      };
    }
    case ProductActionTypes.SET_CANCEL_LOAD_CURRENCY_TOKEN: {
      return {
        ...state,
        currencyCancellationTokenSource: action.currencyCancellationTokenSource,
      };
    }
    case ProductActionTypes.GET_ORDERS: {
      return {
        ...state,
        loadingOrdersForAccountId: action.loadingOrdersForAccountId,
      };
    }
    case ProductActionTypes.SET_ACCOUNT_ORDERS: {
      return {
        ...state,
        accountsOrders: action.accountsOrders,
      };
    }
    case ProductActionTypes.SET_TABLE_PROPS_FOR_SERIAL: {
      return {
        ...state,
        serialTableState: action.serialTableState,
      };
    }
    case ProductActionTypes.GET_DELETE_ACCOUNT_SERIALS: {
      return {
        ...state,
        deleteAccountSerialsToDisplay: action.deleteAccountSerialsToDisplay,
        allDeleteAccountSerials: action.allDeleteAccountSerials,
        loadingDeleteAccountSerials: action.loadingDeleteAccountSerials,
      };
    }
    case ProductActionTypes.SET_CANCEL_GET_ALL_SERIALS_TOKEN: {
      return {
        ...state,
        getAllSerialsCancellationTokenSource: action.getAllSerialsCancellationTokenSource,
      };
    }
    case ProductActionTypes.UPDATE_PRODUCT_ID_REPO: {
      return {
        ...state,
        allProductIdsRepo: action.allProductIdsRepo,
      };
    }
    case ProductActionTypes.SET_TABLE_PROPS_FOR_DELETE_ACCOUNTS_SERIAL: {
      return {
        ...state,
        deleteAccountSerialTableState: action.deleteAccountSerialTableState,
      };
    }
    case ProductActionTypes.SET_CANCEL_GET_CHANGE_SERVICES_DESTINATION_OPTIONS_TOKEN: {
      return {
        ...state,
        cancelGetServicesDestinationOptionsTokenSource: action.cancelGetServicesDestinationOptionsTokenSource,
      };
    }
    case ProductActionTypes.SET_LOADING_OPTIONS_TO_CHANGE_SERVICES_TO: {
      return {
        ...state,
        loadingOptionsToChangeServicesTo: action.loadingOptionsToChangeServicesTo,
      };
    }
    case ProductActionTypes.SET_CANCEL_GET_ACCOUNT_HAS_ANY_CHANGE_SERVICES_OPTIONS_TOKEN: {
      return {
        ...state,
        cancelGetAccountHasAnyChangeServicesOptionsTokenSource: action.cancelGetAccountHasAnyChangeServicesOptionsTokenSource,
      };
    }
    case ProductActionTypes.SET_ACCOUNT_HAS_ANY_CHANGE_SERVICES_OPTIONS: {
      return {
        ...state,
        loadingAccountHasAnyChangeServicesOptions: action.loadingAccountHasAnyChangeServicesOptions,
        accountHasChangeServicesOptions: action.accountHasChangeServicesOptions,
      };
    }
    default:
      return state;
  }
};
