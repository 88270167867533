import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Tooltip, Grid } from "@cuda-networks/bds-core";
import BackTo from "../../BackTo";
import IProduct from "../../../models/Products/IProduct";

interface CustomDialogProps {
  open: boolean;
  title: string;
  onCancel: () => void;
  onBack?: (e: any) => void;
  onNext: () => void;
  onEscapePressed?: () => void;
  actionInProgress: boolean;
  activeStep: number;
  getStepContent: (step: number) => React.ReactNode;
  showBackButton: boolean;
  showBackLink: boolean;
  product?: IProduct;
  nextButtonEnabled: boolean;
  nextButtonTooltip: string;
  nextStepButtonLabel: string;
  loadingOptions?: boolean;
}

const CustomDialog: React.FC<CustomDialogProps> = ({ open, title, onCancel, onBack, onEscapePressed, showBackButton, showBackLink, product, onNext, actionInProgress, activeStep, getStepContent, nextButtonEnabled, nextButtonTooltip, nextStepButtonLabel, loadingOptions = false }) => {
  return (
    <Dialog
      data-testid="changeServicesDialog"
      disableEscapeKeyDown={actionInProgress}
      open={open}
      onClose={(event: any, reason: any) => {
        if (event.key === "Escape") {
          if (onEscapePressed) onEscapePressed();
        }

        if (reason !== "backdropClick") {
          if (!actionInProgress) {
            onCancel();
          }
        }
      }}
    >
      <DialogTitle data-testid="changeServicesDialogTitle" id="alert-dialog-title">
        <Typography
          variant="h5"
          style={{
            fontWeight: 500,
            paddingLeft: "10px",
            paddingTop: "10px",
          }}
        >
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {activeStep === (0 || 1) && showBackLink && (
          <div style={{ padding: 8 }}>
            <BackTo
              text={`Edit ${product?.subname}`}
              callback={(e: any) => {
                if (onBack) {
                  console.log("onBack");
                  onBack(e);
                }
              }}
              testId="goBackToEditSerialLink"
              disable={actionInProgress}
            />
          </div>
        )}
        <div className="DialogContentDiv" style={{ padding: 8 }}>
          <Grid item xs={12}>
            {getStepContent(activeStep)}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: 15 }}>
        <div style={{ display: "flex", gap: "8px" }}>
          <Button data-testid="cancelChangeServicesButton" variant="text" size="large" onClick={onCancel} disabled={actionInProgress}>
            CANCEL
          </Button>
          {activeStep !== 0 && showBackButton && (
            <Button data-testid="backChangeServicesButton" variant="text" size="large" onClick={onBack} disabled={actionInProgress}>
              BACK
            </Button>
          )}
          <Tooltip title={!nextButtonEnabled || actionInProgress || loadingOptions ? nextButtonTooltip : ""}>
            <Button data-testid="nextChangeServicesButton" type="submit" size="large" disabled={!nextButtonEnabled || actionInProgress || loadingOptions} isLoading={actionInProgress || loadingOptions} onClick={onNext}>
              {nextStepButtonLabel}
            </Button>
          </Tooltip>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
