import React, { useEffect, useState } from "react";
import ConfirmationCheckbox from "../../ConfirmationCheckbox";
import ToStep from "./DialogSteps/ToStep";
import ConfirmationMessage from "./ConfirmationMessage";
import SummaryStep from "./DialogSteps/SummaryStep";
import IProduct from "../../../models/Products/IProduct";
import IChangeServiceSelection from "../../../models/Products/IChangeServiceSelection";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import CustomChangeServicesDialog from "./CustomChangeServicesDialog";
import useStepState from "./useStepStateHook";

interface IChangeServicesDialogOneToOneProps {
  showDialog: boolean;
  onSubmit: (selection: IChangeServiceSelection) => void;
  onCancel: () => void;
  onHandleBackToEditSerial: () => void;
  actionInProgress: boolean;
  from: IProduct;
  to: IProduct[];
}

const ToDescription = (
  <>
    In the fields below, select the single Service to which you want to change your previously selected service. When complete, click the <b>Next</b> button.
  </>
);

const ChangeServicesDialogOneToOne: React.FC<IChangeServicesDialogOneToOneProps> = ({ from, to, showDialog, onSubmit, onCancel, onHandleBackToEditSerial, actionInProgress }) => {
  const loadingOptionsToChangeServicesTo = useSelector((state: IAppState) => state.productState.loadingOptionsToChangeServicesTo);
  const [activeStep, setActiveStep] = useState(1);
  const [confirmed, setConfirmed] = useState(false);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const [nextButtonTooltip, setNextButtonTooltip] = useState("");
  const [selection, setSelection] = useState<IChangeServiceSelection>({ from: [from] });

  const { nextStepButtonLabel, title } = useStepState({
    selection,
    activeStep,
    confirmed,
    nextButtonEnabled,
    setNextButtonEnabled,
    setNextButtonTooltip,
    dialogStepsTitle: "Change Service",
    confirmationStepTitle: "Confirm Change Service",
  });

  useEffect(() => {
    setConfirmed(false);
  }, [selection]);

  const onConfirmChanged = (value: boolean) => {
    setConfirmed(value);
    setNextButtonEnabled(value);
  };

  const onToSelectionChanged = (product: IProduct, service: string) => {
    if (product.id === "") {
      setSelection({ ...selection, to: undefined, toService: service });
      setNextButtonEnabled(false);
      return;
    }
    setSelection({ ...selection, to: product, toService: service });
    setNextButtonEnabled(true);
  };

  const nonSelectedProduct = to.filter(product => !selection?.from?.some(selected => selected.id === product.id));
  nonSelectedProduct.sort((a, b) => a.name.localeCompare(b.name));

  function getStepContent(step: number) {
    switch (step) {
      //case 0:
      //return <FromStep activatedProducts={nonSelectedProduct} selection={selection} onFromSelectionChanged={onFromSelectionChanged} onDelete={onDelete} />;
      case 1:
        return <ToStep availableOptions={to} selection={selection} onToSelectionChanged={onToSelectionChanged} description={ToDescription} />;
      case 2:
        return <SummaryStep selection={selection} />;
      case 3:
        return <ConfirmationCheckbox message={<ConfirmationMessage />} onConfirmChanged={value => onConfirmChanged(value)} confirmed={confirmed} />;
    }
  }

  const handleNext = () => {
    switch (activeStep) {
      case 0:
      case 1:
      case 2:
        setActiveStep(activeStep + 1);
        break;
      case 3:
        onSubmit(selection);
        break;
    }
  };

  const handleBack = (e: any) => {
    e.preventDefault();
    if (activeStep === 1) {
      onHandleBackToEditSerial();
    } else if (activeStep > 1) {
      setActiveStep(activeStep - 1);
    } else {
      onCancel();
    }
  };

  return <CustomChangeServicesDialog open={showDialog} title={title} onCancel={onCancel} product={from} showBackLink={true} showBackButton={activeStep !== (0 || 1)} onEscapePressed={onHandleBackToEditSerial} onBack={handleBack} onNext={handleNext} actionInProgress={actionInProgress} activeStep={activeStep} getStepContent={getStepContent} nextButtonEnabled={nextButtonEnabled} nextButtonTooltip={nextButtonTooltip} nextStepButtonLabel={nextStepButtonLabel} loadingOptions={loadingOptionsToChangeServicesTo} />;
};

export default ChangeServicesDialogOneToOne;
