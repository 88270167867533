import { useState, useEffect } from "react";
import IChangeServiceSelection from "../../../models/Products/IChangeServiceSelection";

interface UseStepStateProps {
  selection: IChangeServiceSelection;
  activeStep: number;
  confirmed: boolean;
  nextButtonEnabled: boolean;
  setNextButtonEnabled: (value: boolean) => void;
  setNextButtonTooltip: (value: string) => void;
  dialogStepsTitle: string;
  confirmationStepTitle: string;
}

const useStepState = ({ selection, activeStep, confirmed, dialogStepsTitle, confirmationStepTitle, nextButtonEnabled, setNextButtonEnabled, setNextButtonTooltip }: UseStepStateProps) => {
  const [nextStepButtonLabel, setNextStepButtonLabel] = useState("NEXT");
  const [title, setTitle] = useState("Change Services");

  const NoServicesSelected = "Select services to change";
  const NoServicesSelectedToChangeTo = "Select a service to change to";

  useEffect(() => {
    let enabled = false;
    let tooltip = "";
    let label = "NEXT";
    let newTitle = dialogStepsTitle;

    switch (activeStep) {
      case 0:
        if (selection.from.length > 0 && !selection.from.some(item => item.id === "")) {
          enabled = true;
        } else {
          tooltip = NoServicesSelected;
        }
        break;

      case 1:
        if (selection.to) {
          enabled = true;
        } else {
          tooltip = NoServicesSelectedToChangeTo;
        }
        break;

      case 2:
        enabled = true;
        label = "CONFIRM";
        break;

      case 3:
        tooltip = "";
        enabled = confirmed;
        label = "CONFIRM";
        newTitle = confirmationStepTitle;
        break;

      default:
        break;
    }

    setNextButtonEnabled(enabled);
    setNextButtonTooltip(tooltip);
    setNextStepButtonLabel(label);
    setTitle(newTitle);
  }, [selection, activeStep, confirmed, setNextButtonEnabled, setNextButtonTooltip, dialogStepsTitle, confirmationStepTitle]);

  return {
    nextStepButtonLabel,
    title,
  };
};

export default useStepState;
